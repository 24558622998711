// title: { type: String, required: true },
// meeting_type_id: { type: Types.ObjectId, default: null, ref: 'MeetingTypes' },
// meeting_type: { type: mongoose.Schema.Types.Mixed, default: {} },
// description: { type: String, required: false },
// date: { type: Date, required: false },
// duration: { type: Number, required: false },

// employees: [{ type: mongoose.Schema.Types.ObjectId, required: false }],

// meeting_files: [{ type: mongoose.Schema.Types.ObjectId, required: false }],
// share_meeting_files: { type: Boolean, default: true },

import React, { useEffect } from "react";
import { View, Card, Text, KeyboardAwareScrollView } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomSelect, CustomUpload } from "../../../mycomponents/DynamicForm";
import ReportScreenSingle from "../../../screens/MainScreens/CRUD/ReportScreenSingle";

export const DateIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#FF9D9D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M14.78 27h18.3c2.884 0 4.386-1.438 4.386-4.186V7.186C37.466 4.438 35.964 3 33.08 3h-18.3c-2.871 0-4.373 1.425-4.373 4.186v15.628c0 2.748 1.502 4.186 4.373 4.186Zm-.014-2.555c-1.103 0-1.714-.553-1.714-1.683V10.95c0-1.13.611-1.683 1.714-1.683h18.327c1.103 0 1.715.553 1.715 1.683v11.813c0 1.13-.611 1.683-1.715 1.683H14.766Zm6.606-10.748h.784c.478 0 .638-.142.638-.591v-.758c0-.462-.16-.603-.638-.603h-.784c-.479 0-.638.141-.638.603v.758c0 .45.16.59.638.59Zm4.345 0h.785c.465 0 .624-.142.624-.591v-.758c0-.462-.16-.603-.625-.603h-.784c-.478 0-.637.141-.637.603v.758c0 .45.159.59.637.59Zm4.333 0h.784c.479 0 .638-.142.638-.591v-.758c0-.462-.16-.603-.638-.603h-.784c-.465 0-.625.141-.625.603v.758c0 .45.16.59.625.59ZM17.039 17.83h.77c.48 0 .639-.14.639-.603v-.758c0-.45-.16-.59-.638-.59h-.771c-.479 0-.638.14-.638.59v.758c0 .462.16.603.638.603Zm4.333 0h.784c.478 0 .638-.14.638-.603v-.758c0-.45-.16-.59-.638-.59h-.784c-.479 0-.638.14-.638.59v.758c0 .462.16.603.638.603Zm4.345 0h.785c.465 0 .624-.14.624-.603v-.758c0-.45-.16-.59-.625-.59h-.784c-.478 0-.637.14-.637.59v.758c0 .462.159.603.637.603Zm4.333 0h.784c.479 0 .638-.14.638-.603v-.758c0-.45-.16-.59-.638-.59h-.784c-.465 0-.625.14-.625.59v.758c0 .462.16.603.625.603Zm-13.011 4.122h.77c.48 0 .639-.14.639-.59v-.758c0-.462-.16-.603-.638-.603h-.771c-.479 0-.638.14-.638.603v.758c0 .45.16.59.638.59Zm4.333 0h.784c.478 0 .638-.14.638-.59v-.758c0-.462-.16-.603-.638-.603h-.784c-.479 0-.638.14-.638.603v.758c0 .45.16.59.638.59Zm4.345 0h.785c.465 0 .624-.14.624-.59v-.758c0-.462-.16-.603-.625-.603h-.784c-.478 0-.637.14-.637.603v.758c0 .45.159.59.637.59Z"
        />
    </Svg>
)

export const AgendaIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#FFE99D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M33.654 5.25h-.904V4.5a.75.75 0 1 0-1.5 0v.75h-4.5V4.5a.75.75 0 1 0-1.5 0v.75h-4.5V4.5a.75.75 0 1 0-1.5 0v.75h-.904A2.85 2.85 0 0 0 15.5 8.095v15.31a2.85 2.85 0 0 0 2.846 2.845h15.308a2.85 2.85 0 0 0 2.846-2.846V8.095a2.85 2.85 0 0 0-2.846-2.845ZM24.28 18.53l-2.25 2.25a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.971.97 1.72-1.72a.75.75 0 0 1 1.06 1.06Zm0-6.75-2.25 2.25a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.971.97 1.72-1.72a.75.75 0 0 1 1.06 1.06ZM32 20.25h-5.25a.75.75 0 1 1 0-1.5H32a.75.75 0 1 1 0 1.5Zm0-6.75h-5.25a.75.75 0 1 1 0-1.5H32a.75.75 0 1 1 0 1.5Z"
        />
    </Svg>
)

export const InviteIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#9DE2FF" rx={15.09} ry={14.5} />
        <G fill="#464F60" clipPath="url(#a)">
            <Path d="M31.18 8.066v2.995l1.901-1.413-1.901-1.582ZM23.156 1.42a1.813 1.813 0 0 0-2.312-.006l-.767.652h3.858l-.779-.646Z" />
            <Path d="M23.802 18.053a2.96 2.96 0 0 1-1.793.592c-.646 0-1.28-.206-1.8-.592l-9.616-7.178v12.31a1.806 1.806 0 0 0 1.793 1.817h19.228a1.806 1.806 0 0 0 1.793-1.818V10.875l-9.605 7.178Z" />
            <Path d="m10.925 9.648 1.841 1.37V8.122l-1.841 1.527ZM13.974 3.273v8.645l6.942 5.168c.646.47 1.534.47 2.18 0l6.876-5.125V3.273H13.974Zm4.286 4.83h3.26c.332 0 .604.272.604.604a.605.605 0 0 1-.604.603h-3.26a.605.605 0 0 1-.604-.603c0-.332.272-.604.604-.604Zm7.486 4.226H18.26a.606.606 0 0 1-.604-.604c0-.332.272-.604.604-.604h7.486c.332 0 .603.272.603.604a.605.605 0 0 1-.603.604Z" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M10 1h24v24H10z" />
            </ClipPath>
        </Defs>
    </Svg>
)

export const FilesIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#E0FF9D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M27.469 10.031c-.905 0-1.64-.736-1.64-1.64V3h-8.392a2.581 2.581 0 0 0-2.578 2.578v18.844A2.581 2.581 0 0 0 17.438 27h13.125a2.581 2.581 0 0 0 2.578-2.578v-14.39h-5.672Zm-8.766 9.844h3.409a.703.703 0 0 1 0 1.406h-3.409a.703.703 0 0 1 0-1.406ZM18 16.828c0-.388.315-.703.703-.703h10.313a.703.703 0 1 1 0 1.406H18.703a.703.703 0 0 1-.703-.703Zm11.016-4.453a.703.703 0 1 1 0 1.406H18.703a.703.703 0 0 1 0-1.406h10.313Z"
        />
        <Path
            fill="#464F60"
            d="M27.234 8.388c0 .13.105.234.235.234h5.358a2.571 2.571 0 0 0-.493-.643l-4.52-4.276a2.585 2.585 0 0 0-.58-.414v5.1Z"
        />
    </Svg>
)

export const meetingfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const AttendanceComponent = ({ field, useForm, tKey, tIndex }) => {
        const meeting_id = useForm((state) => state.formObject['_id']);
        const [status, setStatus] = React.useState("Present");

        return <View w-100 flex>
            <View marginB-10>
                <CustomSelect
                    value={status}
                    onChange={(value) => {
                        setStatus(value);
                    }}
                    options={[
                        { label: 'Present', value: 'Present' },
                        { label: 'Absent', value: 'Absent' },
                        { label: 'Late', value: 'Late' },
                        { label: 'Excuse Requested', value: 'Excuse Requested' },
                        { label: 'Excused', value: 'Excused' },
                    ]}
                />
            </View>

            <ListingScreen
                key={status}
                {...{
                    moduleName: 'MeetingAttendances',
                    hideWrapper: true,
                    extraFilters: {
                        meeting_id: meeting_id,
                        status: status
                    },
                    hideViews: true,
                    hideMainHeader: true,
                    showExports: true,
                }}
                hiddenColumns={["meeting.date", "meeting.title"]}
                disabledClone={true}
                disableDelete={true}
                title={" "}
            />
        </View>
    };

    const MeetingAttendanceSummary = ({ field, useForm, tKey, tIndex }) => {
        const meeting_id = useForm((state) => state.formObject['_id']);

        return <View w-100 flex>
            <ReportScreenSingle
                moduleName={"MeetingAttendanceSummary"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'meeting_id',
                    quickFilterValue: meeting_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'meeting_id',
                        quickFilterValue: meeting_id,
                        hideQuickFilter: true,
                        name: "MeetingAttendanceSummary",
                    }
                }}
            />
        </View>
    };


    const OverviewComponent = ({ field, useForm, tKey, tIndex }) => {
        const meeting_id = useForm((state) => state.formObject['_id']);
        const meeting_type = useForm((state) => state.formObject['meeting_type']);
        const date = useForm((state) => state.formObject['date']);
        const duration = useForm((state) => state.formObject['duration']);
        const description = useForm((state) => state.formObject['description']);
        const meeting_files = useForm((state) => state.formObject['meeting_files']);
        const employees = useForm((state) => state.formObject['employees']);

        return <KeyboardAwareScrollView
            extraHeight={300}
            enableOnAndroid={true}
            keyboardShouldPersistTaps='handled'
            contentContainerStyle={{
                flexGrow: 1,
                padding: 10
            }}
            style={{
                height: Dimensions.get('window').height - 100
            }}><Card padding-20 flex>
                <View w-100 flex>
                    <View row marginB-10>
                        <View row>
                            <DateIcon />
                        </View>

                        <View marginL-10>

                            <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>
                                {/* add duration which is in ms */}

                                {moment(date).format('DD MMM YYYY hh:mm A')} - {moment(date).add(duration, 'ms').format('hh:mm A')}
                            </Text>
                        </View>
                    </View>

                    <View style={{
                        height: 1,
                        backgroundColor: '#e6e6e6',
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 10
                    }} />

                    <View row marginB-10>
                        <View row>
                            <AgendaIcon />
                        </View>

                        <View marginL-10>
                            <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Agenda</Text>
                            {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: description }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 120} source={{ html: (description || "") }} />}
                        </View>
                    </View>

                    <View style={{
                        height: 1,
                        backgroundColor: '#e6e6e6',
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 10
                    }} />

                    <View row marginB-10 style={{
                        flex: 1
                    }}>
                        <View row>
                            <InviteIcon />
                        </View>

                        <View marginL-10 style={{
                            width: '90%'
                        }}>
                            <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Invited</Text>
                            <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                                {employees?.length > 0 ? employees.map((item, i) => {
                                    return <View key={i} row>
                                        <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>{item?.name}</Text>
                                        {i !== employees.length - 1 ? <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>, </Text> : null}
                                    </View>
                                }) : <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>No one invited</Text>}
                            </View>
                        </View>
                    </View>

                    <View style={{
                        height: 1,
                        backgroundColor: '#e6e6e6',
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 10
                    }} />

                    <View row marginB-10>
                        <View row>
                            <FilesIcon />
                        </View>

                        <View marginL-10>
                            <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Files</Text>
                            <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                                {meeting_files?.length > 0 ? <CustomUpload
                                    mimeType={"image/*,application/pdf"}
                                    moduleName="Employees"
                                    multiple={true}
                                    value={meeting_files || []}
                                    onChange={(value) => {

                                    }}
                                    showNaming={false}
                                    readOnly={true}
                                /> : <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>No files attached</Text>}
                            </View>
                        </View>
                    </View>
                </View>
            </Card>
        </KeyboardAwareScrollView>
    };

    const formFields = [
        {
            label: '',
            type: "custom",
            visible: true,
            width: '100%',
            tab: "Overview",
            component: OverviewComponent,
        },
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter Title',
            visible: true,
            width: '100%',
            tab: "General",
        },
        {
            label: 'Type',
            key: 'meeting_type_id',
            type: 'select',
            placeholder: 'Select Type',
            visible: true,
            width: '100%',
            options: response.MeetingTypes ? response.MeetingTypes.map((item) => {
                return { label: item?.name, value: item?._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "MeetingTypes", field_key: 'meeting_type_id', params })
            },
            tab: "General",
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select Location',
            visible: true,
            width: '100%',
            options: response.Locations ? response.Locations.map((item) => {
                return { label: item?.name, value: item?._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Locations", field_key: 'location_id', params })
            },
            tab: "General",
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter Date',
            visible: true,
            width: '100%',
            value: new Date(Math.ceil(Date.now() / (15 * 60 * 1000)) * (15 * 60 * 1000)),
            tab: "General",
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {

            }
        },
        {
            label: 'Duration',
            key: 'duration',
            type: 'duration',
            duration_options: ["minutes", "hours"],
            placeholder: 'Enter Duration',
            visible: false,
            width: '100%',
            value: 60 * 60 * 1000,
            tab: "General",
        },
        {
            label: 'Start Time',
            key: 'start_time',
            type: 'time',
            placeholder: 'Enter End Date',
            visible: true,
            width: '100%',
            value: moment(new Date(Math.ceil(Date.now() / (15 * 60 * 1000)) * (15 * 60 * 1000))).add(0, 'minutes').toDate(),
            tab: "General",
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {

            }
        },
        {
            label: 'End Time',
            key: 'end_time',
            type: 'time',
            placeholder: 'Enter End Date',
            visible: true,
            width: '100%',
            value: moment(new Date(Math.ceil(Date.now() / (15 * 60 * 1000)) * (15 * 60 * 1000))).add(60, 'minutes').toDate(),
            tab: "General",
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {

            }
        },
        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
            tab: "General",
        },
        {
            label: 'Meeting Files',
            key: 'meeting_files',
            type: 'upload',
            visible: true,
            width: '100%',
            mimeType: '*/*',
            moduleName: moduleName,
            multiple: true,
            tab: "General",
        },
        {
            label: 'Is private meeting',
            key: 'is_private_meeting',
            type: 'switch',
            placeholder: 'Enter Is private meeting',
            visible: true,
            width: '100%',
            value: false,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                });
            },
            tab: "General",
        },
        {
            label: "",
            key: "MembershipPaymentsComponent",
            type: "custom",
            visible: isEdit ? false : true,
            component: MeetingAttendanceSummary,
            tab: "Attendance Summary",
        },
        {
            label: "",
            key: "AttendanceComponent",
            type: "custom",
            visible: isEdit ? false : true,
            component: AttendanceComponent,
            tab: "Attendance",
        },
        {
            label: "Agent",
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Select Agent',
            visible: true,
            width: '100%',
            multiple: true,
            options: response.Employees ? response.Employees.map((item) => {
                return { ...item, label: item?.name, value: item?._id }
            }) : [],
            tab: "General",
            group_by: "custom_grouping",
        }
    ];

    if (view) {
        // remove all "General" tab fields
        return formFields.filter((item) => item.tab !== "General");
    } else {
        return formFields.filter((item) => item.tab !== "Overview").filter((item) => item.tab !== "Attendance");
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });

    }

    return formFields;
}

export const meetingMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const CustomViewHeader = (props) => {
    // padding: 15px 15px 10px;
    // margin: 10px 10px 0px;
    // background-color: rgb(244, 247, 249);
    // border-radius: 10px 10px 0px 0px;

    return <View padding-15 margin-10 marginB-0 paddingB-10 backgroundColor="rgb(244, 247, 249)" style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 130 }}>
        <View spread row top>
            <View row >
                <View marginT-10>
                    <Text style={{
                        fontFamily: 'SourceSansProBold',
                        fontSize: 22
                    }}>{props?.connectedObject?.title}</Text>

                    <View>
                        <Text marginV-5 style={{
                            fontStyle: 'italic',
                            color: "#727171",
                        }}>{props?.connectedObject?.meeting_type?.name} | {moment(props?.connectedObject?.date).format('DD MMM YYYY hh:mm A')}</Text>
                    </View>


                    <View row centerV>
                        <View style={{
                            backgroundColor: moment(props?.connectedObject?.date).isBefore(moment()) ? "#f44336" : "#4caf50",
                            padding: 5,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 10
                        }}>
                            <Text white>{moment(props?.connectedObject?.date).isBefore(moment()) ? "Past" : "Upcoming"}</Text>
                        </View>

                        {/* <View row>
                            <Text darkCardTitle>Created At - </Text>
                            <Text darkCardTitle>{moment(props?.connectedObject?.createdAt).format('DD MMM YYYY hh:mm A')}</Text>
                        </View> */}
                    </View>
                </View>
            </View>

            {props.HeaderExtra}
        </View>
    </View>;
}

export const meetingMain = {
    fields: meetingfields,
    mobileCard: null,
    CustomViewHeader: CustomViewHeader,
    customHeaderHeight: 70,
    CustomTitle: "Meetings/Events"
}