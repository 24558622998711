// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState, ActivityIndicator, } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, DeviceEventEmitter } from "react-native"

import { api } from "../../../services/api"
import { colors, spacing } from "../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, FloatingButton, TouchableOpacity, Dialog, PanningProvider, Chip, ActionSheet } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomSelect, CustomTextField } from "../../../mycomponents/DynamicForm"
import DynamicTable from "../../../mycomponents/DynamicTable"
import { useNavigation } from '@react-navigation/native';
import Svg, { Path, G, Circle } from "react-native-svg";
import { LinearGradient } from 'expo-linear-gradient'
import { BoxShadow } from 'react-native-shadow'
import { useStores } from "../../../models";
import FormScreen from "./FormScreen"
import { useMatch, useNavigate, useRoutes } from "react-router-dom";
import { Sidebar } from "../../../mycomponents/SideBar"
import { Dropdown } from "antd"
import { Icon } from "react-native-eva-icons"
import { HeaderWeb } from "../../../mycomponents/HeaderWeb"

const LayoutScreen = observer(function LayoutScreen(props) {
  const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

  return (<View flex row={Platform.OS == 'web' && getSideBarPosition == 'left'} style={{
    height: Dimensions.get('window').height,
    overflow: 'auto',
  }}>
    {props.children}
  </View>
  )
})

export default LayoutScreen

const $container = {
  flex: 1,
}